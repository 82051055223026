import { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from "react-router-dom";
import { getUser } from "../services";
import "../styles/IndexPage.css";
import AOS from 'aos';
import { isTokenValid } from "../services/auth";

const IndexPage = () => {
    const [user, setUser] = useState(null);
    const isAuthenticated = isTokenValid();

    const fetchUser = async () => {
        if (isAuthenticated) {
            try {
                const response = await getUser();
                setUser(response);
            } catch (error) {
                console.log(error);

            }
        }
    }

    useEffect(() => {
        fetchUser();
    }, [isAuthenticated])

    useEffect(() => {
        // Initialize AOS animation
        AOS.init({
            duration: 1000, // Animation duration in milliseconds
            easing: 'ease',
            once: false, // Animation happens only once as you scroll down
        });
    });

    return (
        <div className="container-fluid p-0">
            {/* Top Navigation Bar */}
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img
                            src={"/assets/img/echowavs-no-bg.png"}
                            height={50}
                            alt="Echowavs Logo"
                        />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="#home">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#about">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#apps">Our Apps</a>
                            </li>
                            {isAuthenticated ? (
                                <>
                                    <li className="nav-item">
                                        <a href="https://accounts.echowavs.com.ng/manage-account/profile" className="nav-link">
                                            Manage Account
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <img
                                            src={user?.profile_pic || "/assets/img/echowavs-logo.png"}
                                            alt="Profile"
                                            className="rounded-circle bg-info index-profile"
                                            height={40}
                                            width={40}
                                        />
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="nav-item">
                                        <a href="https://accounts.echowavs.com.ng/signin" className="nav-link">
                                            Sign In
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="https://accounts.echowavs.com.ng/signup" className="nav-link">
                                            Sign Up
                                        </a>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <header className="index-page-container hero-section text-center py-5" id="home" data-aos="fade-down">
                <div className="container">
                    <h1 className="display-4">Welcome to Echowavs</h1>
                    <p className="lead">Innovating the digital and subscription landscape, one solution at a time.</p>
                    <a href="#apps" className="btn btn-primary btn-lg">Explore Our Apps</a>
                </div>
            </header>

            {/* About Section */}
            <section className="about-section py-5" id="about" data-aos="fade-down">
                <div className="container">
                    <h2 className="text-center mb-4" data-aos="slide-right">About Echowavs</h2>
                    <p className="text-center" data-aos="slide-left">
                        At Echowavs, we are at the forefront of digital transformation. Our mission is to develop innovative solutions across various industries,
                        from user-friendly mobile applications to powerful enterprise-level platforms. Specializing in subscription-based services (SaaS), we offer a range of products
                        designed to simplify and enhance everyday tasks, whether it's purchasing data and airtime, managing financial transactions, or delivering tailored software solutions.
                        Our commitment to excellence ensures that we provide seamless, scalable, and secure experiences that meet the evolving needs of our users and businesses alike.
                    </p>

                </div>
            </section>

            {/* Apps Section */}
            <section className="apps-section py-5 bg-light" id="apps" data-aos="fade-up">
                <div className="container">
                    <h2 className="text-center mb-4" data-aos="zoom-in">Our Apps</h2>
                    <div className="row" data-aos="zoom-in">
                        <div className="col-md-4 mb-4">
                            <div className="card h-100 text-center">
                                <div className="card-body">
                                    <h3 className="card-title">Echowavs Sub</h3>
                                    <p className="card-text">
                                        A revolutionary app changing the way we buy Airtime and Data.
                                    </p>
                                    <a href="https://sub.echowavs.com.ng" className="btn btn-primary">
                                        Explore Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Add more app cards if needed */}
                    </div>
                </div>
            </section>

            {/* Footer Section */}
            <footer className="footer-section bg-dark text-white py-4">
                <div className="container text-center">
                    <div className="d-flex justify-content-center mb-3">
                        <Link to="#privacy" className="text-white mx-2">Privacy Policy</Link>
                        <Link to="#terms" className="text-white mx-2">Terms of Service</Link>
                    </div>
                    <p>© 2024 Echowavs. All Rights Reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default IndexPage;
