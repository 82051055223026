import { jwtDecode } from 'jwt-decode';
import { decryptData } from '../utils/encryptionUtils';
import { getCookie } from '../utils/cookieUtils';

export const isTokenValid = () => {
    const token = getCookie('access_token');
    const decrypToken = token ? decryptData(token) : null;
    if (!decrypToken) return false;

    try {
        const { exp } = jwtDecode(decrypToken);
        return Date.now() < exp * 1000; // Compare with current timestamp
    } catch (error) {
        return false; // Invalid token format
    }
};


