import Cookies from 'js-cookie';

// Utility functions to manage cookies

// Set a cookie with optional expiry (default 7 days)
export const setCookie = (name, value, days = 7) => {
    // Setting the cookie with SameSite=None for cross-origin support and Secure flag for production
    Cookies.set(name, value, {
        domain: '.echowavs.com.ng',
        expires: days,
        path: '/',        // Ensure it's accessible across your entire domain
        secure: true,     // Set to true for secure cookies in production (HTTPS)
        sameSite: 'None'  // Needed for cross-origin requests
    });
};

// Get a cookie by its name
export const getCookie = (name) => {
    return Cookies.get(name);  // Directly return the value of the cookie
};

// Erase a cookie
export const eraseCookie = (name) => {
    Cookies.remove(name, 
        { 
            path: '/',
            domain: '.echowavs.com.ng',
        });  // Ensure it matches the path
};
