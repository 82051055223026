import { eraseCookie, getCookie } from "../utils/cookieUtils";
import { decryptData } from "../utils/encryptionUtils";
import API from "./api";



export const getUser = async () => {
    try {
        const token = getCookie('access_token')
        const decryptToken = token ? decryptData(token) : null;
        const response = await API.get(`account/user/`,
            {
                headers: {
                    Authorization: `Bearer ${decryptToken}`
                }
            }

        );
        return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const logout = () => {
    eraseCookie('access_token');
    eraseCookie('refresh_token');
    window.location.href = '/'; // Redirect to login page
}